.proj-card-wrap {
    justify-content: center;
    width: 90vw;
    max-width: 1500px;
    gap: 2vw;
    flex-wrap: wrap;
}

.proj-card {
    background-color: hsl(358, 29%, 55%);
    background-size: 90%;
    background-clip: content-box;
    background-repeat: no-repeat;
    background-position: left center;
    width: 250px;
    height: 250px;
    border-radius: 25px;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.25s ease-in;
}

.proj-card:hover {
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05);
}

.card-text-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    height: 33%;
    width: 250px;
    background-color: #945A5C;
    border-radius: 0 0 25px 25px;
    transition: height 0.25s ease-out 0.25s, border-radius 0.25s ease-out 0.25s;
}

.proj-card:hover .card-text-wrap {   
    height: 100%;
    display: inline-block;
    border-radius: 25px;
    transition: height 0.25s ease-out, border-radius 0.25s ease-out;
}

.card-text {
    margin: 1rem 0 0.5rem 1rem;
    max-width: 175px;
    background-color: transparent;
}

.card-desc {
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: #AD6A6C;
    font-size: small;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    transition: visibility 0.25s ease-out, opacity 0.25s ease-out;
}

.proj-card:hover .card-text-wrap .card-desc {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.25s ease-out 0.25s, opacity 0.25s ease-out 0.25s;
}

.lang {
    font-size: small;
    visibility: hidden;
    opacity: 0;
    width: fit-content;
    min-width: 75px;
    padding: 0.25rem;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    margin-top: 0.5rem;
    transition: visibility 0.25s ease-out, opacity 0.25s ease-out;
}


.proj-card:hover .lang {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.25s ease-out 0.25s, opacity 0.25s ease-out 0.25s;
}

.pic {
    width: 192px;
    height: 144px;
    border-radius: 25px;
    margin-bottom: 10px;
    transition: height 0.25s ease-out 0.25s, margin 0.25s ease-out 0.25s;
}

.proj-card:hover .pic {
    height: 0;
    margin: 0;
    transition: height 0.25s ease-out, margin 0.25s ease-out;
}
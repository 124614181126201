@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --contact: #61E294;
  --about: #E5D4ED;
  --projects: #AD6A6C;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins";
  color: #f1f1f1;
  background-color: #0e0e0e;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  flex-direction: column;
}

.top-wrap {
  gap: 5vh;
  margin-bottom: 5vh;
}

.title {
  margin-top: 5vh;
  text-align: center;
}

.subtitle {
  margin-top: 2.5vh;
  text-align: center;
  max-width: 75vw;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.page-top {
  width: 100%;
  height: 44vh;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  padding: 0.5rem;
  background-color: #f1f1f1;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  border-radius: 100%;
}

.top-text {
  font-size: 2.5rem;
  font-weight: 400;
}

.page-bottom {
  width: 100%;
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.col {
  height: 100%;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.left {
  width: 50%
}

.mid {
  width: 30%;
}

.right {
  width: 20%;
}

.button {
  height: 100%;
  width: 100%;
  border-radius: 75px;
  display: flex;
  text-decoration: none;
  transition: transform 0.2s;
  margin: 5px;
  overflow: hidden;
}

.button:hover {
  -ms-transform: scale(0.9); /* IE 9 */
  -webkit-transform: scale(0.9); /* Safari 3-8 */
  transform: scale(0.9); 
}

.button:hover .carousel-ani {
  opacity: 1;
  visibility: visible;
}

.button:hover .large-card-text {
  opacity: 0;
  visibility: hidden;
}

.button:hover  .medium-card-text {
  opacity: 0;
  visibility: hidden;
}

.large-card {
  align-items:first baseline;
}

.projects {
  background-color: var(--projects);
}

.large-card-text {
  font-size: 2rem;
  padding: 10% 0 0 10%;
}

.medium-card {
  
}

.contact {
  color: #0e0e0e;
  background-color: var(--contact);
}

.about {
  color: #0e0e0e;
  background-color: var(--about);
}

.medium-card-text {
  margin-top: auto;
  font-size: 1.75rem;
  padding: 0 0 10% 10%;
  background-color: transparent;
}

.small-card {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.small-logo {
  width: 25%;
  margin-bottom: 8px;
}

.li-logo, .li-logo * {
  background-color: #0A66C2;
}

.gh-logo, .gh-logo * {
  background-color: #24292F;
}

.small-card-text {
  font-size: 1rem;
}

.carousel-ani {
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  
  visibility: hidden;
  opacity: 0;
}

.carousel-ani p {
  animation: marquee 2s linear infinite;
  -webkit-animation: marquee 2s linear infinite;
  -moz-animation: marquee 2s linear infinite;
  background-color: transparent;
  display: inline-block;
}

.large-ani {
  margin-top: 10vh;
}

.large-ani p{
  font-size: 3rem;
}

.med-ani {
  margin-top: 10vh;
}

.med-ani p {
  color: #0e0e0e;
  font-size: 2.5rem;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
  }
}

@media only screen and (max-width: 600px) {
  .page-bottom {
    flex-direction: column;
  }
  .left, .mid, .right {
    flex-direction: row;
    width: 100%;
  }
  .left {
    height: 50%;
  }
  .mid {
    height: 30%;
  }
  .right {
    height: 20%;
  }
  .small-logo {
    width: 20%;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .button:hover {
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9); 
  }
  .button:hover .carousel-ani {
    opacity: 0;
    visibility: hidden;
  }
  .button:hover  .large-card-text {
    opacity: 1;
    visibility: visible;
  }
  .button:hover .medium-card-text {
    opacity: 1;
    visibility: visible;
  }
  .large-ani, .med-ani {
    margin-top: 0px;
  }
}
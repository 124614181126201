.techs {
    width: 80vw;
    max-width: 1100px;
    text-align: center;
}

.techs-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E5D4ED;
    width: 35vw;
    max-width: 400px;
    height: 30vh;
    min-height: 250px;
    border-radius: 50px;
    padding: 0 2rem;
    margin: 2vh;
}

.techs-card * {
    background-color: transparent;
    color: #0E0E0E;
    text-align: center;
}

.techs-card > *:first-child {
    font-weight: 700;
}

.card-title {
    background-color: #0E0E0E;
    color: #F1F1F1;
    padding: 10px;
    border-radius: 99px;
    width: 100%;
    margin-top: 2vh;
}

.techs-group {
    height: 100%;
}

.list-gap {
    gap: 5px;
}

.tech-logo {
    height: 25px;
}

@media only screen and (max-width: 600px) {
    .row {
        flex-direction: column;
    }
    .techs-card {
        max-width: none;
        width: 100%;
    }
}
.footer {
    width: 100%;
    height: 5vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f1f1f1;
    margin-top: 25px;
}

.footer-text {
    font-size: smaller;
}